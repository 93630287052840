import { Station } from "./map-station.model";

export const rebuildStationList: Station[] = [
  {
    name: "Pumwani Dispensary",
    lon: 40.0684649,
    lat: -1.6109016,
    description: "Manual weather station installed and functioning",
    state: "active",
  },
  {
    name: "Rhoka Dispensary",
    lat: -1.2328883,
    lon: 39.9678649,
    description: "Manual weather station installed and functioning",
    state: "active",
  },
  {
    name: "Reuben Mwewe School",
    lat: -2.2118083,
    lon: 40.18145,
    description: "Manual weather station installed and functioning",
    state: "active",
  },
  {
    name: "Hurara primary school",
    lat: -2.6431,
    lon: 40.1398933,
    description: "Manual weather station installed and functioning",
    state: "active",
  },
  {
    name: "Furaha Primary School",
    lat: -2.47395,
    lon: 40.1856416,
    description: "Manual weather station installed and functioning",
    state: "active",
  },
  {
    name: "Semi Karo Dispensary",
    lat: -2.5087866,
    lon: 40.2855016,
    description: "Manual weather station installed and functioning",
    state: "active",
  },
  {
    name: "Kibisu Chief",
    lat: -2.353855,
    lon: 40.1246983,
    description: "Manual weather station installed and functioning",
    state: "active",
  },
  {
    name: "Ozi Dispensary",
    lat: -2.5117399,
    lon: 40.4553233,
    description: "Manual weather station installed and functioning",
    state: "active",
  },
  {
    name: "Chifiri Chief Office",
    lat: -1.294486666,
    lon: 39.70715333,
    description: "Manual weather station installed and functioning",
    state: "active",
  },
  {
    name: "Garsen Dcc",
    lat: -2.339695,
    lon: 40.100816666666,
    description: "Manual weather station installed and functioning",
    state: "active",
  },
];
