import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import mapboxgl, { Map } from "!mapbox-gl";
import "../style/map.css";
import bbox from "@turf/bbox";
import { multiPoint } from "@turf/helpers";
import { StaticImage } from "gatsby-plugin-image";
import { Station } from "./map-stations/map-station.model";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoibG9yZW56b3RyaW0iLCJhIjoiY2puMGY4N21qMTcyeTNwcDdhNnM2a3hzbyJ9.04wWYgeEbKomSJbkXQC8Lw";
const mapContainerStyle = {
  width: "100%",
  height: "400pt",
};

export type MapProps = {
  stationList: Station[],
  mapSectionTitle: string
}

export default function MapHeal(props: MapProps) {
  const mapContainerRef = useRef(null);

  useEffect(() => {
    const map = new Map({
      container: mapContainerRef.current,
      accessToken: MAPBOX_TOKEN,
      style: "mapbox://styles/mapbox/streets-v11",
    });
    const box = bbox(multiPoint(props.stationList.map((s) => [s.lon, s.lat])));
    map.fitBounds([box[0], box[1], box[2], box[3]], { padding: 50 });
    map.addControl(new mapboxgl.NavigationControl(), "top-right");

    props.stationList.map((s) => {
      const placeholder = document.createElement("div");
      ReactDOM.render(Popup(s), placeholder);
      const popup = new mapboxgl.Popup().setDOMContent(placeholder);
      const marker = document.createElement("div");
      marker.className = "circle " + s.state;
      new mapboxgl.Marker(marker, { anchor: "center" })
        .setLngLat([s.lon, s.lat])
        .setPopup(popup)
        .addTo(map);
    });

    return () => map.remove();
  }, []);

  return (
    <>
      <h2>{props?.mapSectionTitle}</h2>
      <div
        className="round shadow"
        ref={mapContainerRef}
        style={mapContainerStyle}
      />
      <div className="columns" style={{ marginTop: "10pt" }}>
        <div className="column">
          <div className="marker">
            Installed and functioning
            <div className="circle active" />
          </div>
        </div>
        <div className="column">
          <div className="marker">
            Installed but inactive
            <div className="circle inactive" />
          </div>
        </div>

        <div className="column">
          <div className="marker">
            To be installed soon
            <div className="circle soon" />
          </div>
        </div>
      </div>
    </>
  );
}

function Popup(s: Station) {
  return (
    <div>
      {s.name === "Balesa" && (
        <StaticImage
          alt={s.name}
          className="round"
          src="../../images/projects/HEAL/balesa.JPG"
        />
      )}

      {s.name === "Dukana" && (
        <StaticImage
          alt={s.name}
          className="round"
          src="../../images/projects/HEAL/dukana.JPG"
        />
      )}

      {s.name === "Filtu" && (
        <StaticImage
          alt={s.name}
          className="round"
          src="../../images/projects/HEAL/filtu.JPG"
        />
      )}

      {s.name === "Gas" && (
        <StaticImage
          alt={s.name}
          className="round"
          src="../../images/projects/HEAL/gas.JPG"
        />
      )}

      {s.name === "Kalacha" && (
        <StaticImage
          alt={s.name}
          className="round"
          src="../../images/projects/HEAL/kalacha.JPG"
        />
      )}

      {s.name === "Nort Horr" && (
        <StaticImage
          alt={s.name}
          className="round"
          src="../../images/projects/HEAL/north horr.JPG"
        />
      )}

      <div className="station-name">{s.name}</div>
      <div className="station-description">{s.description}</div>
    </div>
  );
}
