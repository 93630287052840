import { Station } from "./map-station.model";

export const healStationList: Station[] = [
  {
    name: "Golbo",
    lon: 40.570258,
    lat: 4.66466,
    description: "Manual weather station to be installed soon",
    state: "soon",
  },
  {
    name: "Osobay",
    lon: 40.676075,
    lat: 4.913368,
    description: "Manual weather station to be installed soon",
    state: "soon",
  },
  {
    name: "Harbali",
    lon: 40.054829,
    lat: 5.394638,
    description: "Manual weather station to be installed soon",
    state: "soon",
  },
  {
    name: "Filtu",
    lon: 40.663315,
    lat: 5.118949,
    description: "Manual weather station to be installed but inactive",
    state: "inactive",
  },
  {
    name: "Malkagalla",
    lon: 38.79971,
    lat: 1.26992333333333,
    description: "Manual weather station to be installed soon",
    state: "active",
  },
  {
    name: "Bissan Biliqo",
    lon: 38.478895,
    lat: 0.893173333333333,
    description: "Manual weather station to be installed soon",
    state: "active",
  },
  {
    name: "Gafarsa",
    lon: 38.5875416666666,
    lat: 0.93744,
    description: "Manual weather station to be installed soon",
    state: "active",
  },
  {
    name: "Amhado Amin",
    lon: 40.8751659602251,
    lat: 4.99076491737314,
    description: "Manual weather station to be installed soon",
    state: "soon",
  },
  {
    name: "Leparua (Burst ward)",
    lon: 37.4343216666666,
    lat: 0.304321666666666,
    description: "Manual weather station to be installed soon",
    state: "active",
  },
  {
    name: "Oldonyiro (Oldonyiro ward)",
    lon: 36.9868716666666,
    lat: 0.626189999999999,
    description: "Manual weather station to be installed soon",
    state: "active",
  },
  {
    name: "Nort Horr",
    lon: 37.0714617,
    lat: 3.32396399999999,
    description: "Manual weather station installed and functioning",
    state: "active",
  },
  {
    name: "Balesa",
    lon: 37.3455243,
    lat: 3.6093123,
    description: "Manual weather station installed and functioning",
    state: "active",
  },
  {
    name: "Dukana",
    lon: 37.2701191,
    lat: 3.9982506,
    description: "Manual weather station installed and functioning",
    state: "active",
  },
  {
    name: "Gas",
    lon: 36.8283502,
    lat: 3.0679451,
    description: "Manual weather station installed and functioning",
    state: "active",
  },
  {
    name: "Kalacha",
    lon: 37.4236118,
    lat: 3.1272262,
    description: "Manual weather station installed and functioning",
    state: "active",
  },
];
