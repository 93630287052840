import React from "react";
import BaseLayout from "../components/baseLayout";
import HeroTitle from "../components/heroTitle";
import MapHeal from "../components/MapHeal";
import { GatsbyImage } from "gatsby-plugin-image";
import HeaderHelmet from "../components/headerHelmet";
import { BlogSectionContainer } from "../components/smallsComponents";
import { Partner } from "../components/partner";
import { graphql } from "gatsby";
import "../style/projectblog.css";
import { healStationList } from "../components/map-stations/onehealt-heal.stations";
import { rebuildStationList } from "../components/map-stations/rebuild.stations";

export default function ProjectPage({ data }) {
  const { markdownRemark, allFile } = data;
  const { frontmatter, html } = markdownRemark;

  const baseUrl = "https://trimweb.it/";
  const name = frontmatter.name;
  const description = frontmatter.description;
  const partners = frontmatter.partners;

  const url = new URL(frontmatter.path, baseUrl);
  const image = new URL(
    frontmatter.img.childImageSharp.openGraphImg.images.fallback.src,
    baseUrl
  );

  const pdfs = allFile.edges
    .map((x) => x.node)
    .filter((x) => frontmatter.pdfs.includes(x.name));

  return (
    <BaseLayout>
      <HeaderHelmet
        url={url}
        title={name}
        description={description}
        image={image}
      />

      <div className="relative">
        <GatsbyImage
          style={{ maxHeight: "600pt", width: "100%" }}
          image={frontmatter.img.childImageSharp.contentImg}
          alt={name}
          objectFit={"cover"}
        />
        <div className="photo-credits has-text-weight-bold is-size-7">
          {frontmatter.imgCredits}
        </div>
      </div>

      <HeroTitle title={name} description={description} />

      <div className="container">
        <BlogSectionContainer>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </BlogSectionContainer>

        {name === "One Health for HEAL" && (
          <BlogSectionContainer>
            <MapHeal 
              mapSectionTitle="Ground Weather Stations installed in the HEAL Project" 
              stationList={healStationList}
            />
          </BlogSectionContainer>
        )}

        {name === "REBUILD" && (
          <BlogSectionContainer>
            <MapHeal 
              mapSectionTitle="Ground Weather Stations installed in the REBUILD Project" 
              stationList={rebuildStationList}
            />
          </BlogSectionContainer>
        )}

        {pdfs.length > 0 && (
          <BlogSectionContainer>
            <div className="columns is-mobile is-centered is-multiline">
              {pdfs.map((pdf, i) => (
                <a
                  className="button my-button"
                  key={i}
                  href={pdf.publicURL}
                  download
                >
                  {pdf.name}.pdf
                </a>
              ))}
            </div>
          </BlogSectionContainer>
        )}

        {partners.length > 0 && (
          <BlogSectionContainer>
            <div className="title">Donors and partners</div>
            <div className="columns is-multiline is-centered is-mobile">
              {partners.map((partnerTag, i) => (
                <Partner partnerTag={partnerTag} key={i} />
              ))}
            </div>
          </BlogSectionContainer>
        )}
      </div>
    </BaseLayout>
  );
}

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        name
        description
        tools
        partners
        path
        pdfs
        imgCredits
        img {
          childImageSharp {
            contentImg: gatsbyImageData(placeholder: BLURRED)
            openGraphImg: gatsbyImageData(
              width: 1200
              height: 630
              formats: [JPG]
            )
          }
        }
      }
    }
    allFile(filter: { extension: { eq: "pdf" } }) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
  }
`;
